import { Component, BaseComponent, Prop, namespace, Watch } from '@zento-lib/components';
import type { ShopByCategoriesData, ImageSizes } from 'theme/stores/cms/types';
import { Carousel, Slide } from '@zento-lib/components/Carousel';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';

import { MainImage } from '../../../atom/MainImage/MainImage';
import { Button } from '../../../atom/Button/Button';
import { Link } from '../../../atom/Link/Link';
import { InnerHTML } from '../../../atom/InnerHTML';

import type { IHomepageCategoriesListImageLarge } from './CategoryListImageLarge.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

/**
 * Homepage Category List Image Large
 *
 * Renders a list with specific categories links and images
 */
@Component({})
export class HomepageCategoriesListImageLarge extends BaseComponent<IHomepageCategoriesListImageLarge, unknown> {
  private static T = {
    seeAll: 'see_all',
  };

  /**
   * An object containing component data (heading title and components links and images)
   */
  @Prop({ type: Object, required: true })
  categories: ShopByCategoriesData & { imageSizes?: ImageSizes; subcategoriesPage?: boolean };

  /**
   * Determines subcategories carousel
   */
  @Prop({ type: Boolean, default: true })
  subcategoriesCarousel?: boolean;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  @Watch('categories.items')
  onItemsChange() {
    this.$data.items = this.categories.items;
  }

  data() {
    return {
      items: [],
    };
  }

  /**
   * Determines config image sizes
   */
  get imageSizes() {
    return this.categories.imageSizes ?? this.extended.$config.zento.images.listCategoriesLarge;
  }

  mounted() {
    if (this.categories.subcategoriesPage) {
      this.$data.items = this.categories.items;
    } else {
      this.$data.items = this.categories.items.sort((a: any, b: any) => a.position - b.position);
    }
  }

  render() {
    const config = this.extended.$config.zento.images;
    const catFilterConfig = config.listCategoriesLarge;
    const layout = catFilterConfig.layout;

    return (
      <section class={style.categoriesListImageLarge} key='categories-filter'>
        <div
          class={{
            [style.container]: true,
            [style.smallContainer]: this.categories.width === 'small',
            [style.largeContainer]: this.categories.width === 'large',
            [style.fullContainer]: this.categories.width === 'full_width',
            [style.layout3]: layout === 'layout3',
            [style.oneItem]: layout === 'layout3' && this.categories.items.length === 1, // TODO: should find a better way
            [style.layout4]: layout === 'layout4',
            [style.layout5]: layout === 'layout5',
            [style.layout6]: layout === 'layout6',
            [style.layout7]: layout === 'layout7',
            [style.layout10]: layout === 'layout10',
            [style.layout12]: layout === 'layout12',
          }}>
          {this.categories.heading ? (
            <h2
              class={{
                [style.centerTitle]: this.categories.heading_position === 'center',
                [style.rightTitle]: this.categories.heading_position === 'right',
              }}
              key='top-categories-title'>
              {this.categories.heading}
              <div>
                {this.categories.seeAll ? (
                  this.categories.follow === 'follow_link' &&
                  this.categories.follow_link &&
                  this.categories.follow_link?.length ? (
                    <Button
                      styleType='ghost'
                      handler={() => this.$router.push(this.extended.localizedRoute(this.categories.follow_link))}
                      class={{
                        [style.followBtn]: true,
                        [style.seeAll]: true,
                        [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                        [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                      }}>
                      <span>{this.getTranslation({ id: HomepageCategoriesListImageLarge.T.seeAll })}</span>
                    </Button>
                  ) : (
                    <Link
                      to={{
                        name: 'Categories Widget List',
                        params: {
                          heading: this.categories.heading,
                          categories: this.categories,
                        },
                      }}
                      class={{
                        [style.seeAll]: true,
                        [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                        [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                      }}
                      key='see-all'>
                      {this.getTranslation({ id: HomepageCategoriesListImageLarge.T.seeAll })}
                    </Link>
                  )
                ) : null}
              </div>
            </h2>
          ) : null}

          <div
            class={{
              [style.categoriesBox]: true,
              [style.categoriesBoxCarousel]: true,
              [style.categoriesBoxSubcategories]: this.categories?.imageSizes?.layout === 'subcategories',
            }}>
            {this.subcategoriesCarousel && catFilterConfig.categoriesFilterCarousel ? (
              <Carousel
                perPage={this.categories.perPageDesktop ? parseFloat(this.categories.perPageDesktop) : 5}
                perPageCustom={[
                  [
                    320,
                    this.categories.perPageMobilePortrait
                      ? parseFloat(this.categories.perPageMobilePortrait)
                      : catFilterConfig.mobileCarouselItemsPortrait ?? 1.5,
                  ],
                  [
                    480,
                    this.categories.perPageMobileLandscape
                      ? parseFloat(this.categories.perPageMobileLandscape)
                      : catFilterConfig.mobileCarouselItemsLandscape ?? 2,
                  ],
                  [
                    767,
                    this.categories.perPageTablet
                      ? parseFloat(this.categories.perPageTablet)
                      : catFilterConfig.tabletCarouselItems ?? 3,
                  ],
                  [
                    1025,
                    this.categories.perPageDesktop
                      ? parseFloat(this.categories.perPageDesktop)
                      : catFilterConfig.desktopCarouselItems ?? 5,
                  ],
                ]}
                mouseDrag={true}
                navigationEnabled={this.categories.navigationEnabled ?? true}
                paginationEnabled={this.categories.paginationEnabled ?? false}
                centerMode={this.categories.centerMode ?? true}
                scrollPerPage={this.isDesktop}
                autoplay={catFilterConfig.carouselAutoplay && this.isDesktop}
                loop={catFilterConfig.carouselLoop && this.isDesktop}
                easing='ease-in-out'
                speed={this.isDesktop ? 500 : 200}
                class={{
                  [style.listWrapper]: layout === 'layout3',
                  [style[`listItems-${this.categories.items.length}`]]: layout === 'layout3' || layout === 'layout6',
                }}>
                {this.$data.items.map((c) => {
                  const image = {
                    src: ('widget_image' in c && c.widget_image ? c.widget_image : c.image) as any,
                    loading: '',
                  };

                  return (
                    <Slide
                      key={c.name}
                      class={{
                        [style.hoverAnimation]: this.categories.imageHoverAnimation === 'hoverAnimation',
                        [style.hoverOverlay]: this.categories.imageHoverAnimation === 'hoverOverlay',
                        [style.positionNone]: this.categories.buttonPosition === 'positionNone',
                        [style.topLeft]: this.categories.buttonPosition === 'topLeft',
                        [style.centerLeft]: this.categories.buttonPosition === 'centerLeft',
                        [style.topCenter]: this.categories.buttonPosition === 'topCenter',
                        [style.center]: this.categories.buttonPosition === 'center',
                        [style.topRight]: this.categories.buttonPosition === 'topRight',
                        [style.centerRight]: this.categories.buttonPosition === 'centerRight',
                        [style.bottomLeft]: this.categories.buttonPosition === 'bottomLeft',
                        [style.bottomCenter]: this.categories.buttonPosition === 'bottomCenter',
                        [style.bottomRight]: this.categories.buttonPosition === 'bottomRight',
                      }}>
                      <Link to={'/' + c.url_path}>
                        <MainImage
                          image={image}
                          folder=''
                          resize={layout === 'layout4' ? 'xc' : 'xf'}
                          width={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 320
                              : this.imageSizes.width
                          }
                          height={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 239
                              : this.imageSizes.height
                          }
                          tabletWidth={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 936
                              : this.imageSizes.tabletWidth
                          }
                          tabletHeight={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 702
                              : this.imageSizes.tabletHeight
                          }
                          desktopWidth={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 936
                              : this.imageSizes.desktopWidth
                          }
                          desktopHeight={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 702
                              : this.imageSizes.desktopHeight
                          }
                          class={{
                            [style.imageContainer]:
                              layout === 'layout3' &&
                              (this.categories.items.length === 1 || this.categories.items.length === 2),
                          }}
                          key='category-image'
                        />

                        <div
                          class={{
                            [style.categoryContentBox]:
                              layout === 'layout3' &&
                              (this.categories.items.length === 1 || this.categories.items.length === 2),
                            [style.categoryContent]: layout === 'layout6' || layout === 'layout10',
                          }}>
                          {this.categories.showCategoryTitle !== 'false' ? (
                            <h2
                              class={{
                                [style.categoryName]: true,
                                [style.hideCategoryName]: layout === 'layout3' && this.categories.items.length > 3,
                              }}>
                              {c.name}
                            </h2>
                          ) : null}

                          {this.categories.showCategoryDescription !== 'false' &&
                          c?.description &&
                          c?.description?.length ? (
                            <InnerHTML contents={c.description} class={style.categoryDesc} />
                          ) : null}

                          {layout !== 'layout5' &&
                          layout !== 'layout6' &&
                          layout !== 'layout10' &&
                          layout !== 'layout12' ? ( // TODO: should be fixed
                            <span
                              class={{
                                [style.button]: true,
                                [style.buttonIcon]: this.categories.buttonType === 'icon',
                                [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                                [style.buttonCategoryName]: layout === 'layout3' && this.categories.items.length > 3,
                              }}>
                              {(layout === 'layout3' && this.categories.items.length > 3) ||
                              layout === 'layout2' ||
                              this.categories.showCategoryTitle !== 'false' ? ( // TODO: should be fixed
                                <span>{c.name}</span>
                              ) : this.categories.buttonText ? (
                                <span>{this.categories.buttonText}</span>
                              ) : null}
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    </Slide>
                  );
                })}
              </Carousel>
            ) : (
              <ul
                class={{
                  [style.listWrapper]: layout === 'layout3',
                  [style[`listItems-${this.categories.items.length}`]]: layout === 'layout3' || layout === 'layout6',
                  [style.layoutSubcategories]: this.categories?.imageSizes?.layout === 'subcategories',
                }}>
                {this.$data.items.map((c) => {
                  const image = {
                    src: ('widget_image' in c && c.widget_image ? c.widget_image : c.image) as any,
                    loading: '',
                  };

                  return (
                    <li
                      style={{ order: c.position }}
                      class={{
                        [style.hoverAnimation]: this.categories.imageHoverAnimation === 'hoverAnimation',
                        [style.hoverOverlay]: this.categories.imageHoverAnimation === 'hoverOverlay',
                        [style.positionNone]: this.categories.buttonPosition === 'positionNone',
                        [style.topLeft]: this.categories.buttonPosition === 'topLeft',
                        [style.centerLeft]: this.categories.buttonPosition === 'centerLeft',
                        [style.topCenter]: this.categories.buttonPosition === 'topCenter',
                        [style.center]: this.categories.buttonPosition === 'center',
                        [style.topRight]: this.categories.buttonPosition === 'topRight',
                        [style.centerRight]: this.categories.buttonPosition === 'centerRight',
                        [style.bottomLeft]: this.categories.buttonPosition === 'bottomLeft',
                        [style.bottomCenter]: this.categories.buttonPosition === 'bottomCenter',
                        [style.bottomRight]: this.categories.buttonPosition === 'bottomRight',
                      }}>
                      <Link to={'/' + c.url_path}>
                        <MainImage
                          image={image}
                          folder=''
                          resize={layout === 'layout4' ? 'xc' : 'xf'}
                          width={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 320
                              : this.imageSizes.width
                          }
                          height={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 239
                              : this.imageSizes.height
                          }
                          tabletWidth={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 936
                              : this.imageSizes.tabletWidth
                          }
                          tabletHeight={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 702
                              : this.imageSizes.tabletHeight
                          }
                          desktopWidth={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 936
                              : this.imageSizes.desktopWidth
                          }
                          desktopHeight={
                            layout === 'layout3' &&
                            (this.categories.items.length === 1 || this.categories.items.length === 2)
                              ? 702
                              : this.imageSizes.desktopHeight
                          }
                          class={{
                            [style.imageContainer]:
                              layout === 'layout3' &&
                              (this.categories.items.length === 1 || this.categories.items.length === 2),
                          }}
                          key='category-image'
                        />

                        <div
                          class={{
                            [style.categoryContentBox]:
                              layout === 'layout3' &&
                              (this.categories.items.length === 1 || this.categories.items.length === 2),
                            [style.categoryContent]: layout === 'layout6',
                          }}>
                          {this.categories.showCategoryTitle !== 'false' ? (
                            <h3
                              class={{
                                [style.categoryName]: true,
                                [style.hideCategoryName]: layout === 'layout3' && this.categories.items.length > 3,
                              }}>
                              {c.name}
                            </h3>
                          ) : null}

                          {this.categories.showCategoryDescription !== 'false' &&
                          c?.description &&
                          c?.description?.length ? (
                            <InnerHTML contents={c.description} class={style.categoryDesc} />
                          ) : null}

                          {layout !== 'layout5' &&
                          layout !== 'layout6' &&
                          layout !== 'layout10' &&
                          layout !== 'layout12' ? ( // TODO: should be fixed
                            <span
                              class={{
                                [style.button]: true,
                                [style.buttonIcon]: this.categories.buttonType === 'icon',
                                [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                                [style.buttonCategoryName]: layout === 'layout3' && this.categories.items.length > 3,
                              }}>
                              {(layout === 'layout3' && this.categories.items.length > 3) || layout === 'layout2' ? ( // TODO: should be fixed
                                <span>{c.name}</span>
                              ) : this.categories.buttonText ? (
                                <span>{this.categories.buttonText}</span>
                              ) : null}
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </section>
    );
  }
}
